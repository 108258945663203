.input-element {
    max-width: 420px;
    width: 100%;
    svg {
        margin-top: 1px;
    }
}

.select-applicant-container {
    max-width: 420px;
    width: 100%;

    .applicant-type-select {
        height: 36px;
        svg {
            margin-right: .5rem;
        }
    }
}
