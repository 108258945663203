#phone-country-code {
    margin-top: 2px;
    min-width: 60px;
    &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle; font-size: 28px;
        border-radius: 16px;
        margin-right: 1rem;
        position: relative;
        top: 20px;
    }
}

.enter-phone-info {
    font-size: 17px;
}