.back-button {
    max-width: fit-content;
    &:hover {
        background-color: transparent;
    }
    svg {
        margin-bottom: 1px;
    }
}

#select-language {
    min-width: 100px;
}

.title {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
}