@import "../../shared/styles/variables.less";

.language-container {
  display: flex;
  align-items: center;
  fieldset {
    border: none;
  }
  .selected-language {
    max-width: 128px;
  }
  .MuiAutocomplete-endAdornment {
    top: 12px;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiOutlinedInput-root {
    padding-right: 30px;
  }
}

.language-filter-container {
  padding: 1rem 16px 8px 16px;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;

  .language-filter {
    fieldset {
      border: 1px solid #B3B3B3;
    }
  }
}

.language-list {
  max-height: 450px;
}
