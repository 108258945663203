@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import "./shared/styles/variables.less";

* {
  font-family: "Inter";
}

.h-dvh {
  height: 100dvh;
}

#root {
  width: 100%;
  display: flex;
  justify-content: center;
}

@layer base {
  :root {
    --color-primary: @color-primary;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  stroke-width: 5px;
  stroke: var(--neutral-10, #ccc);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.language-item {
  padding: 0.5rem 1rem;
  margin-left: 4px;
  border-radius: 0.5rem;
  &-active {
    background: @color-gray-default;
  }

  &-non-active {
    &:hover {
      background: #f8f7f7;
    }
  }
}

.w-48 {
  width: 48px;
}

.hidden {
  display: none;
}
